import { parseWithZod } from "@conform-to/zod";
import {
  type ActionFunctionArgs,
  data,
  type LoaderFunctionArgs,
} from "@remix-run/node";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import loginImage from "~/assets/images/login-chart.svg";
import loginbgwave from "~/assets/images/login_bg-wave.png";
import tempLogo from "~/assets/images/temp_logo.svg";

import { LoginForm, loginSchema } from "~/components/LoginForm";
import {
  createUserSession,
  login,
  requireAnonymous,
} from "~/utils/auth.server";

export async function loader({ request }: LoaderFunctionArgs) {
  await requireAnonymous(request);
  return null;
}

export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData();
  const submission = await parseWithZod(formData, {
    schema: (intent) =>
      loginSchema.transform(async (data, ctx) => {
        if (intent !== null) return { ...data, session: null };

        const session = await login(data).catch((error) => {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: error.message,
          });
          return null;
        });

        if (!session) return z.NEVER;

        return { ...data, session };
      }),
    async: true,
  });

  if (submission.status !== "success" || !submission.value.session) {
    return data(
      { result: submission.reply({ hideFields: ["password"] }) },
      { status: submission.status === "error" ? 400 : 200 },
    );
  }

  const { session, redirectTo } = submission.value;

  const { isLoggedOff, sessionUser } = session;

  return createUserSession(sessionUser, redirectTo ?? "/", isLoggedOff);
}

export default function Login() {
  const { t } = useTranslation();

  return (
    <main className="h-screen grid grid-cols-12">
      <div className="flex flex-wrap justify-center items-center p-8 col-span-12 lg:col-span-6 bg-white">
        <div className="flex w-full flex-wrap justify-center">
          <div className="max-w-sm w-full flex flex-col items-center">
            <img src={tempLogo} alt="Logo" className="m-auto h-4 w-36" />

            <h1 className="text-3xl mb-4 mt-5 text-neutral-600 font-semibold text-center">
              {t("login.signin")}
            </h1>

            <p className=" text-neutral-400 mb-16 font-medium text-center">
              {t("login.information")}
            </p>
            <LoginForm />
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundImage: `radial-gradient(80.88% 80.88% at 100% 4.31%, #0047FF 0%, #0D276C 100%)`,
        }}
        className="hidden lg:flex col-span-12 lg:col-span-6 bg-gradient-to-r from-primary-500 to-primary-600 bg-cover bg-center bg-no-repeat"
      >
        <div className="p-8 mt-1 flex flex-col justify-center items-center align-middle relative">
          <img
            src={loginImage}
            alt="loging-chart"
            className="w-full max-w-3xl"
          />
          <img
            src={loginbgwave}
            alt="login-wave"
            className="absolute bottom-10 w-full"
          />

          <h3 className="text-white mb-4 font-semibold text-2xl w-full h-8 text-center">
            {t("login.welcome-message")}
          </h3>

          <p className="text-white opacity-60 max-w-md text-center">
            {t("login.welcome-description1")}
          </p>
          <p className="text-white opacity-60 max-w-md text-center">
            {t("login.welcome-description2")}{" "}
          </p>
        </div>
      </div>
    </main>
  );
}
